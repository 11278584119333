import {
  FETCH_VOUCHERS,
  PUSH_VOUCHERS,
  UPDATE_VOUCHERS,
  MOVE_VOUCHERS,
  REMOVE_VOUCHERS,
 } from '../actions/vouchersActions';
 import findIndex from "lodash/findIndex"
 import arrayMove from "array-move"

const INITIAL_STATE = {
  data: [],
}

export default function voucherReducer(state = INITIAL_STATE, action){
  switch(action.type){
    case FETCH_VOUCHERS:
      return {
        ...state,
        data:action.data,
      }
    case PUSH_VOUCHERS:
      return {
        ...state,
        data:[action.data, ...state.data],
      }
    case UPDATE_VOUCHERS:
      let updated = state.data.map((v)=>{
        if(v._id === action.data._id) {
          return action.data
        } else return v
      })
      return {
        ...state,
        data: updated
      }
    case MOVE_VOUCHERS:
      let index = findIndex(state.data, function(o) { return o._id === action.data._id; });
      let moved = arrayMove(state.data, index, 0)      
      return {
        ...state,
        data: moved
      }
    case REMOVE_VOUCHERS:
      return {
        ...state,
        data: [...state.data.filter( (item) => item._id !== action.data._id)]
      }
    default:
      return state

  }
}