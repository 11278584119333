import React, { useState } from "react";
import { Formik, FieldArray } from 'formik';
import * as Yup from 'yup';

// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  Row,
  Col
} from "reactstrap";

import client from "../../feathers.js"

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Voucher Name is required'),
  code: Yup.string().required('Voucher Code is required'),
  percentage: Yup.number().required('Percentage is required'),
  numOfUsage: Yup.number().min(1, 'Must be at least 1').required('Number of usage is required'),
  expiryDate: Yup.date().required('Expiry date is required'),
  applyTo: Yup.string().oneOf(['product', 'specs'], 'Invalid selection').required('Selection is required'),
});

const VoucherModal = (props) => {
  const [ status, setStatus ] = useState(true)
  const [ nameFocused, setNameFocused ] = useState(false)
  const [ codeFocused, setCodeFocused ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ amountFocused, setAmountFocused ] = useState(false)

  const onSubmit = async(value) => {
    setLoading(true)
    
    value.status = status
    value.oriNumOfUsage = value.numOfUsage
    
    client.authenticate()
    .then(()=>{
      return client.service('vouchers').create(value)
    })
    .then((res) => {
      props.toggleModal()
      props.pushVouchers(res)
      props.notificationOpen(true, 'success', "Voucher " + res.name + " Added Successfully!")
      setLoading(false)
    })
    .catch((err)=>{
      setLoading(false)
      if(err.name === "NotAuthenticated"){
        props.notificationOpen(true, 'danger', "Please Sign-in to continue!")
      }else{
        props.notificationOpen(true, 'danger', err.message)
      }
    })
  }
  
  return (  
    <>
      <Modal
        className="modal-dialog-centered product-modal"
        isOpen={props.modalVisible}
        toggle={() => {props.toggleModal();}}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Add Voucher
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={props.toggleModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <Formik
          initialValues={{
            name: '',
            code: '',
            percentage: 0,
            numOfUsage: 1,
            expiryDate: '',
            applyTo: '',
            variations: [],
            description: ''
          }}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {propsChild => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
            } = propsChild;
            return (
              <Form role="form" onSubmit={handleSubmit}>
                <div className="modal-body">
                  <div className="mb-2">
                    <small className="text-uppercase font-weight-bold">
                      Voucher Details
                    </small>
                  </div>
                  <Row>
                    <Col md="6">
                      <FormGroup
                        className={classnames("mb-3", {
                          focused: nameFocused
                        }, {
                          "has-danger": errors.name && touched.name
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-tag" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Voucher Name"
                            type="text"
                            id="name"
                            value={values.name}
                            onChange={handleChange}
                            onFocus={e => setNameFocused(true)}
                            onBlur={e => {
                              setNameFocused(false);
                              handleBlur(e)
                            }}
                          />
                        </InputGroup>
                        {errors.name && touched.name && (
                          <div className="input-feedback">{errors.name}</div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup
                        className={classnames("mb-3", {
                          focused: codeFocused
                        }, {
                          "has-danger": errors.code && touched.code
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fa fa-qrcode"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Voucher Code"
                            type="text"
                            id="code"
                            rows="3"
                            value={values.code}
                            onChange={handleChange}
                            onFocus={e => setCodeFocused(true)}
                            onBlur={e => {
                              setCodeFocused(false);
                              handleBlur(e)
                            }}
                          />
                        </InputGroup>
                        {errors.code && touched.code && (
                          <div className="input-feedback">{errors.code}</div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label>Number of Usage</label>
                        <InputGroup className="input-group-alternative">
                          <Input
                            type="number"
                            id="numOfUsage"
                            value={values.numOfUsage}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            min="1"
                          />
                          {errors.numOfUsage && touched.numOfUsage && (
                            <div className="input-feedback">{errors.numOfUsage}</div>
                          )}
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      {/* Expiry Date */}
                      <FormGroup>
                        <label>Expiry Date</label>
                        <InputGroup className="input-group-alternative">
                          <Input
                            type="date"
                            id="expiryDate"
                            value={values.expiryDate}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.expiryDate && touched.expiryDate && (
                            <div className="input-feedback">{errors.expiryDate}</div>
                          )}
                          </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6">
                      {/* Apply To (Radio Buttons) */}
                      <FormGroup>
                        <label>Apply To</label>
                        <div className="pl-4 d-flex justify-content-between">
                          <label>
                            <Input
                              type="radio"
                              name="applyTo"
                              value="product"
                              onChange={handleChange}
                              checked={values.applyTo === 'product'}
                            />{' '}
                            Product
                          </label>
                          <label>
                            <Input
                              type="radio"
                              name="applyTo"
                              value="specs"
                              onChange={handleChange}
                              checked={values.applyTo === 'specs'}
                            />{' '}
                            Specs
                          </label>
                          {/* <label>
                            <Input
                              type="radio"
                              name="applyTo"
                              value="both"
                              onChange={handleChange}
                              checked={values.applyTo === 'both'}
                            />{' '}
                            Both
                          </label> */}
                        </div>
                        {errors.applyTo && touched.applyTo && (
                          <div className="input-feedback">{errors.applyTo}</div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6">
                      <div className="mb-2">
                        <small className="text-uppercase font-weight-bold">
                          Percentage
                        </small>
                      </div>
                      <FormGroup
                        className={classnames("mb-3", {
                          focused: amountFocused
                        }, {
                          "has-danger": errors.percentage && touched.percentage
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-money-coins" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="15%"
                            type="number"
                            id="percentage"
                            rows="3"
                            value={values.percentage}
                            onChange={handleChange}
                            onFocus={e => setAmountFocused(true)}
                            onBlur={e => {
                              setAmountFocused(false);
                              handleBlur(e)
                            }}
                            onWheel={ event => event.currentTarget.blur() }
                          />
                        </InputGroup>
                        {errors.percentage && touched.percentage && (
                          <div className="input-feedback">{errors.percentage}</div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <div className="mb-2">
                        <small className="text-uppercase font-weight-bold">
                          Voucher Status
                        </small>
                      </div>
                      <FormGroup className="text-center">
                        <UncontrolledDropdown nav inNavbar className="w-100">
                          <DropdownToggle caret color="secondary" className="w-100">
                            {status?"Active":"Disabled"}
                          </DropdownToggle>
                          <DropdownMenu className="w-100">
                            <div className="editInputs-colors-scrollbar mac-scrollbar">
                              <DropdownItem 
                                className="text-capitalize" 
                                value={true} 
                                onClick={() => setStatus(true)}
                              >
                                Active
                              </DropdownItem>
                              <DropdownItem 
                                className="text-capitalize" 
                                value={false} 
                                onClick={() => setStatus(false)}
                              >
                                Disabled
                              </DropdownItem>
                            </div>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </FormGroup>
                    </Col>
                  </Row>

                  {
                    values.applyTo === 'specs' &&
                    <div className="mb-4">
                      <FieldArray name="variations">
                          {({ push, remove }) => (
                            <div>
                              <h6>Variations</h6>
                              <Row>
                                <Col md="4">
                                  Exp: anodizing_color, carbon_fiber_finish
                                </Col>
                              </Row>
                              {values.variations.map((_, index) => (
                                <Row key={index}>
                                  <Col md="4">
                                    <FormGroup>
                                      <Input placeholder="Type" name={`variations.${index}.type`} onChange={handleChange} />
                                    </FormGroup>
                                  </Col>
                                  {/* <Col md="4">
                                    <FormGroup>
                                      <Input placeholder="Value" name={`variations.${index}.value`} onChange={handleChange} />
                                    </FormGroup>
                                  </Col>
                                  <Col md="3">
                                    <FormGroup>
                                      <Input type="number" placeholder="Amount" name={`variations.${index}.amount`} onChange={handleChange} />
                                    </FormGroup>
                                  </Col> */}
                                  <Col md="1">
                                    <Button color="danger" onClick={() => remove(index)}>×</Button>
                                  </Col>
                                </Row>
                              ))}
                              <Button color="primary" onClick={() => push({ type: '' })}>+ Add Variation</Button>
                            </div>
                          )}
                        </FieldArray>
                    </div>
                  }

                  <FormGroup>
                    <div className="mb-2">
                      <small className="text-uppercase font-weight-bold">
                        Description
                      </small>
                    </div>
                    <Input
                      className="form-control-alternative"
                      placeholder="Description"
                      id="description"
                      value={values.description}
                      onChange={handleChange}
                      autoComplete="off"
                      type="textarea"
                      rows="3"
                    />
                  </FormGroup>
                </div>
                <div className="modal-footer">
                  <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => {props.toggleModal();}}
                  >
                    Close
                  </Button>
                  <Button 
                    color="primary" 
                    type="submit"
                    disabled={loading}
                  >
                    {loading? (
                      <div className="spinner-border mr-1" role="status" style={{height: '1rem', width: '1rem'}}>
                        <span className="sr-only">Loading...</span>
                      </div>
                    ):null}
                    Confirm
                  </Button>
                </div>
              </Form>
              );
            }}
          </Formik>
      </Modal>
    </>
  );
}
 
export default VoucherModal;