import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { fetchVouchers, pushVouchers, updateVouchers } from '../redux/actions/vouchersActions';
import moment from 'moment';

// reactstrap components
import {
  Button,
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
} from "reactstrap";

import VoucherModal from "../components/Vouchers/VoucherModal.js"
import VoucherModalEdit from "../components/Vouchers/VoucherModalEdit.js"

import ModalConfirmation from "../components/Extra/ModalConfirmation.js"
import Notifications from "../components/Extra/Notifications.js"

import client from "../feathers"

const PAGESIZE = 10;

function paginationCount(length, currentPage) {
  return {
      total: length,
      per_page: PAGESIZE,
      current_page: currentPage,
      last_page: Math.ceil(length / PAGESIZE),
      from: ((currentPage - 1) * PAGESIZE) + 1,
      to: currentPage * PAGESIZE
  };
};

const Vouchers = (props) => {
  const [ modalVisible, setMmodalVisible ] = useState(false)
  const [ modalVisibleEdit, setMmodalVisibleEdit ] = useState(false)
  const [ modalConfirm, setModalConfirm ] = useState(false)
  const [ dataEdit, setDataEdit ] = useState({})
  const [ statusOnChange, setStatusOnChange ] = useState({id:'', status:true})
  const [ pagination, setPagination ] = useState({})
  const [ message, setMessage ] = useState('')
  const [ color, setColor ] = useState('')
  const [ isOpen, setIsOpen ] = useState(false)

  useEffect(() => {
    fetchData(props.userId, props.fetchVouchers, notificationOpen, 0, 1)
  }, [props.userId, props.fetchVouchers])

  const toggleModal = () => {
    setMmodalVisible(!modalVisible)
  }

  const toggleModalEdit = () => {
    setMmodalVisibleEdit(!modalVisibleEdit)
  }

  const toggleModalConfirm = () => {
    setModalConfirm(!modalConfirm)
  }

  const modalCallback = (e) => {
    voucherStatus(statusOnChange.id, statusOnChange.status)
  }

  const notificationOpen = (bool, color, message) => {
    setIsOpen(bool)
    setColor(color)
    setMessage(message)
  }
  
  const fetchData = (userId, fetchVouchers, notificationOpen, skip, currentPage) => {
    if(userId){
      client.authenticate()
      .then(()=>{
        return client.service('vouchers').find({
          query: {
            userId: userId,
            $skip: skip,
            $sort: {
              dateToFirst: -1
            }
          }
        })
      })
      .then((res) => {
        setPagination(paginationCount(res.total, currentPage))
        fetchVouchers(res.data)
      })
      .catch((err)=>{
        if(err.name === "NotAuthenticated"){
          notificationOpen(true, 'danger', "Please Sign-in to continue!")
        }else{
          notificationOpen(true, 'danger', err.message)
        }
      })
    }
  }

  const voucherStatus = (_id, status) => {
    client.authenticate()
    .then(()=>{
      return client.service('vouchers').patch(_id, {status: !status})
    })
    .then((res) => {
      props.updateVouchers(res)
      notificationOpen(true, 'success', "Vouchers " + res.name + " Edited Successfully!")
    })
    .catch((err)=>{
      if(err.name === "NotAuthenticated"){
        notificationOpen(true, 'danger', "Please Sign-in to continue!")
      }else{
        notificationOpen(true, 'danger', err.message)
      }
    })
  }
  
  const PaginationRender = () => {
    if(pagination.last_page > 0){
      let pageArr = []
      for(let i = 1; i <= pagination.last_page; i++){
       pageArr.push(i)
      }
      return (
        <Pagination
          className="pagination justify-content-end mb-0"
          listClassName="justify-content-end mb-0"
        >
          <PaginationItem className={(pagination.current_page > 1?"":"disabled")}>
            <PaginationLink
              onClick={() => {
                setPagination(paginationCount(pagination.total, pagination.current_page-1)); 
                fetchData(props.userId, props.fetchVouchers, notificationOpen, (pagination.current_page-2)*PAGESIZE, pagination.current_page-1)
              }} 
              tabIndex="-1"
            >
              <i className="fas fa-angle-left" />
              <span className="sr-only">Previous</span>
            </PaginationLink>
          </PaginationItem>
          {pageArr.map((i) => {
            return (
              <PaginationItem key={i} className={(pagination.current_page === i? "active": "")}>
                <PaginationLink
                  onClick={() => {
                    setPagination(paginationCount(pagination.total, i)); 
                    fetchData(props.userId, props.fetchVouchers, notificationOpen, (i-1)*PAGESIZE, i)
                  }} 
                >
                  {i}
                </PaginationLink>
              </PaginationItem>
              )
            })}
          <PaginationItem className={((pagination.current_page+1) > pagination.last_page?"disabled":"")}>
            <PaginationLink
              onClick={() => {
                setPagination(paginationCount(pagination.total, pagination.current_page+1)); 
                fetchData(props.userId, props.fetchVouchers, notificationOpen, (pagination.current_page)*PAGESIZE, pagination.current_page+1)
              }} 
            >
              <i className="fas fa-angle-right" />
              <span className="sr-only">Next</span>
            </PaginationLink>
        </PaginationItem>
       </Pagination>
      )
    }else{
      return null
    }
  }

  return (  
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <span className="mask bg-gradient-default opacity-6" />
      </div>
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Dark table */}
        <Row className="mt-5">
          <div className="col">
            <Card className="bg-default shadow">
              <CardHeader className="bg-transparent border-0">
                <Media>
                  <Media body>
                    <h3 className="text-white mb-0">Vouchers tables</h3>
                  </Media>
                  <Media body className="text-right">
                    <Button
                      color="primary"
                      onClick={toggleModal}
                    >
                      <span className="btn-inner--text">Create</span>
                    </Button>
                  </Media>
                </Media>
              </CardHeader>
              <div className="table-responsive mac-scrollbar">
                <Table className="align-items-center table-dark table-flush">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Vouchers Name</th>
                      <th scope="col">Percentage</th>
                      <th scope="col">Code</th>
                      <th scope="col">Status</th>
                      <th scope="col">Created</th>
                      <th scope="col">Updated</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {props?.vouchersData?.length > 0? (
                      props?.vouchersData?.map((v, i) => {
                        return (
                          <tr key={i}>
                            <th scope="row">
                              <Media className="align-items-center">
                               
                                <Media>
                                  <span className="mb-0 text-sm">
                                    {v.name}
                                  </span>
                                </Media>
                              </Media>
                            </th>
                            <td>
                              {v.percentage}%
                            </td>
                            <td>{v.code}</td>
                            <td>
                              <Badge color="" className="badge-dot mr-4">
                                <i className={v.status? "bg-success": "bg-danger"} />
                                {v.status?"Active":"Disabled"}
                              </Badge>
                            </td>
                            <td>{moment(v.createdAt).format("MMM Do YYYY")}</td>
                            <td>{moment(v.updatedAt).format("MMM Do YYYY")}</td>
                            <td className="text-right">
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  className="btn-icon-only text-light"
                                  href="#pablo"
                                  role="button"
                                  size="sm"
                                  color=""
                                  onClick={(e) => e.preventDefault()}
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-arrow" right>
                                  <DropdownItem
                                    onClick={() => {setDataEdit(v); toggleModalEdit()}}
                                  >
                                    Edit
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={() => 
                                      {
                                        setStatusOnChange({id: v._id, status: v.status});
                                        toggleModalConfirm()
                                      }
                                    }
                                  >
                                    {v.status?"Disabled":"Enable"}
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        )
                      })
                    ): <span style={{padding: '0px 10px'}}>No Record Found!</span>}
                  </tbody>
                </Table>
              </div>
              <CardFooter className="py-4 bg-default">
                <nav aria-label="...">
                  <PaginationRender />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <VoucherModal 
        modalVisible={modalVisible} 
        toggleModal={toggleModal}
        notificationOpen={notificationOpen} 
        pushVouchers={props.pushVouchers}
        rateUSDtoMYR={props.rateUSDtoMYR}
      />
      <VoucherModalEdit 
        dataEdit={dataEdit}
        modalVisible={modalVisibleEdit} 
        toggleModal={toggleModalEdit}
        notificationOpen={notificationOpen} 
        updateVouchers={props.updateVouchers}
        rateUSDtoMYR={props.rateUSDtoMYR}
      />
       <ModalConfirmation 
        modalVisible={modalConfirm} 
        toggleModal={toggleModalConfirm} 
        modalCallback={modalCallback} 
        title="Product Status"
        content={statusOnChange.status?"Are you sure want to Disable this?":"Are you sure want to Enable this?"}
      />
      <Notifications 
        isOpen={isOpen} 
        handleOpen={notificationOpen} 
        message={message} 
        color={color}
      />
    </>
  );
}
 
const mapStateToProps = state => ({
  userId: state.role.details.user?state.role.details.user._id:'',
  vouchersData: state.vouchers.data,
  companyInfo: state.company.data,
  rateUSDtoMYR: state.company.data.rateUSDtoMYR?state.company.data.rateUSDtoMYR:4.13,
});

const mapDispatchToProps = {
  fetchVouchers: fetchVouchers,
  pushVouchers: pushVouchers,
  updateVouchers: updateVouchers,
};
export default connect(mapStateToProps, mapDispatchToProps)(Vouchers);