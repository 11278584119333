//Action Types
export const FETCH_VOUCHERS = 'FETCH_VOUCHERS';
export const PUSH_VOUCHERS = 'PUSH_VOUCHERS';
export const UPDATE_VOUCHERS = 'UPDATE_VOUCHERS';
export const MOVE_VOUCHERS = 'MOVE_VOUCHERS';
export const REMOVE_VOUCHERS = 'REMOVE_VOUCHERS';

//Action Creator
export const fetchVouchers = (data) => ({
   type: FETCH_VOUCHERS,
   data: data
});

export const pushVouchers = (data) => ({
    type: PUSH_VOUCHERS,
    data: data
 });

export const updateVouchers = (data) => ({
   type: UPDATE_VOUCHERS,
   data: data
});

export const moveVouchers = (data) => ({
   type: MOVE_VOUCHERS,
   data: data
});

export const removeVouchers = (data) => ({
    type: REMOVE_VOUCHERS,
    data: data
});